import { useEffect } from 'react';
import { BackButton, Button, Spinner, Text } from 'src/components';
import { Modal } from "src/components/atoms/Modal/Modal";
import { Dropdown } from "src/components/molecules/Dropdown/Dropdown";
import { IconFactory } from "src/factories/icon.factory";
import { PlanCostFacade } from 'src/modules/quoter/controller/facades/plan-cost.facade';
import { PlanFacade } from 'src/modules/quoter/controller/facades/plan.facade';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { QUOTER_PATH } from 'src/routes/paths/quoter.paths';
import { Util } from "src/utils/Util";
import PlanContractingButton from "../../components/PlanContractingButton/PlanContractingButton";
import PlanCost from '../../components/PlanCost/PlanCost';
import PlanCoupon from "../../components/PlanCoupon/PlanCoupon";
import PlanCoverages from "../../components/PlanCoverages/PlanCoverages";
import QuoterContent from "../../components/QuoterContent/QuoterContent";
import './QuoterPlan.scss';

/**
 *
 */
const QuoterPlanPage = (): JSX.Element => {
  const { storedVehicle } = QuoterFacade.useQuoterActors();
  const { plans, currentPlan, defaultPaymentType, setPlanPayment, coverages, person, selectPlan, requirementPrompt, search, showVehicleVin, setShowVehicleVin, nextPage, setRequirementPrompt, redirectToVehicle } = PlanFacade.usePlan();
  const { isUpdating, isLoading } = PlanFacade.usePlanSync();
  const { selectedPlanCost, selectCost, additionalCost, selectedDiscountPlanCost } = PlanCostFacade.usePlanCost(currentPlan);

  /**
   * 
   */
  useEffect(
    () => {
      if (currentPlan) {
        selectCost();
      }
    }, [currentPlan, selectCost]
  );

  /**
   * 
   */
  useEffect(() => {

  }, [requirementPrompt]);

  return (
    <div className="plan">
      {
        !isLoading && plans && person && plans.length > 0 ?
          <>
            <div className="plan__bg">
              <img className="plan__bg__image" alt="bg-plan" src={Util.ASSET.PLAN_BG}></img>
              <div className="plan__bg__color"></div>
            </div>
            <QuoterContent
              backButton={<BackButton to={`${QUOTER_PATH.QUOTER_LOCATION_PATH}${search}`} />}
              title={`¡Listo, ${person?.name}! Ya calculé tu cotización 🤓`}
            >
              <section className="plan__section">
                <div className="plan__section__action-wrapper">


                  <Dropdown
                    placeholder="Forma de pago"
                    cancelSearch
                    onChange={(item) => {
                      if (item) {
                        selectCost(item.id);
                        setPlanPayment(item.id);
                        // setPaymentMethod();
                      }
                    }}
                    defaultValue={defaultPaymentType}
                    items={
                      [
                        { id: PlanCostFacade.PLAN_COST.SUSCRIPTION.id, value: PlanCostFacade.PLAN_COST.SUSCRIPTION.name, info: currentPlan.subscription.cost.getTotal() },
                        { id: PlanCostFacade.PLAN_COST.ANNUAL.id, value: PlanCostFacade.PLAN_COST.ANNUAL.name, info: currentPlan.cost.getTotal() },
                        ...currentPlan.monthly.costs.map(
                          (cost, index) => {
                            return {
                              id: (index + 1) + PlanCostFacade.PLAN_COST.SUSCRIPTION.id,
                              value: `Pago a ${cost.monthsQuantity} meses (Solo tarjeta de crédito)`,
                              info: cost.getAmountByMonth()
                            }
                          }
                        )
                      ]}
                  />

                </div>
              </section>

              <section className="plan__section">

                <PlanCost
                  key={`${selectedPlanCost?.total}${selectedDiscountPlanCost?.total}`}
                  discountCost={selectedDiscountPlanCost}
                  cost={selectedPlanCost}
                />

              </section>

              <section className="plan__section">
                {
                  additionalCost &&
                  <Text type="label" align="center" block>*Después pagas <b>{additionalCost.getTotal()}</b> por 11 cuotas mensuales.</Text>
                }
              </section>

              <section className="plan__section">
                <PlanCoupon />
              </section>

              <section className="plan__section plan__section--center-content plan__section--space-top">
                <Button id="planManagementButton" type="button"
                  loading={isUpdating}
                  disabled={isUpdating}
                  onClick={() => selectPlan()}>
                  Contratar Póliza
                </Button>

              </section>

              <section className="plan__section plan__section--center-content">
                <div className="plan__section__scroll-indicator">
                  <button
                    onClick={(e) => window.scrollTo({ top: document.getElementById('plan-coverages')?.getBoundingClientRect().top! + window.scrollY - 100, behavior: 'smooth' })}
                  >
                    {IconFactory.downArrowIcon()}
                  </button>
                  <div className="plan__section__scroll-indicator__pulse"></div>
                </div>
              </section>

              {
                currentPlan && coverages &&
                <>
                  <section className="plan__section plan__section--relative" id="plan-coverages">

                    <section className="plan__section plan__section--space-top">
                      <Text type="subtitle" align="center">Hablemos de tu auto</Text>
                      <Text type="paragraph" align="center">Personaliza tus coberturas para daños a tu auto</Text>
                    </section>

                    <section className="plan__section">
                      <div className="plan__section__plan-details">
                        <PlanCoverages collapsable coverages={coverages.damageCoverages} vehicle={storedVehicle}>
                          <button type="button" className="plan__section__plan-details__actions" onClick={redirectToVehicle}>
                            <span>
                              Cotizar otro auto
                            </span>
                            {IconFactory.editIcon()}
                          </button>
                        </PlanCoverages>
                      </div>
                    </section>

                    <section className="plan__section plan__section--space-top">
                      <Text type="subtitle" align="center">Otros adicionales que te acompañan</Text>
                      <Text type="paragraph" align="center">Coberturas que te facilitan la vida en situaciones impredecibles</Text>
                    </section>

                    <section className="plan__section plan__section--center-content">
                      <div className="plan__section__scroll-indicator">
                        <button
                          onClick={() => window.scrollTo({ top: document.getElementById('extra-coverages')?.getBoundingClientRect().top! + window.scrollY - 100, behavior: 'smooth' })}
                        >
                          {IconFactory.downArrowIcon()}
                        </button>
                        <div className="plan__section__scroll-indicator__pulse"></div>
                      </div>
                    </section>

                    <section className="plan__section" id="extra-coverages">
                      <div className="plan__section__plan-details">
                        <PlanCoverages coverages={coverages.extraCoverages}>
                          <button type="button" className="planDetails__section__type__action">
                            <span>
                              Cotizar otro auto
                            </span>
                            {IconFactory.editIcon()}
                          </button>
                        </PlanCoverages>
                      </div>
                    </section>
                  </section>
                </>
              }
            </QuoterContent>

            {
              (selectedDiscountPlanCost || selectedPlanCost) &&
              <PlanContractingButton
                hideButton={requirementPrompt}
                onClick={() => selectPlan()}
                cost={selectedDiscountPlanCost ? selectedDiscountPlanCost : selectedPlanCost!}
              />
            }

            <Modal
              show={requirementPrompt}
              position="center"
              backgroundDismiss
            >
              <div className="plan__requirements">
                <Text type="subtitle" align="center">Para adquirir el seguro necesitar&aacute;s los siguientes datos del auto:</Text>

                <div className="plan__requirements__item">
                  <Text type="paragraph" bold>N&Uacute;MERO DE SERIE</Text>
                  <button
                    className="plan__requirements__item__action"
                    type="button"
                    onClick={() => setShowVehicleVin(true)}
                  >
                    <Text type="label" block className="plan__requirements__item__action__text">
                      ¿Dónde está el número de serie?
                    </Text>
                  </button>
                </div>

                <div className="plan__requirements__item">
                  <Text type="paragraph" bold>PLACAS</Text>
                  <Text type="label" block>5 a 7 caracteres alfanum&eacute;ricos</Text>
                </div>
                <div className="plan__requirements__buttons">
                  <Button type="button" fill="none" className="plan__requirements__buttons__button"
                    onClick={() => setRequirementPrompt(false)}
                  >
                    Volver
                  </Button>
                  <Button type="button" className="plan__requirements__buttons__button"
                    onClick={nextPage}
                  >
                    Continuar
                  </Button>
                </div>
              </div>
            </Modal>

            <Modal
              show={showVehicleVin}
              position="center"
            >
              <div className="plan__vin">
                <img src={Util.ASSET.VIN_LOCATION_GUIDE} alt="All possible vin locations in a car." />

                <div className="plan__vin__textWrapper">
                  <Text className="plan__vin__textWrapper__firstParagraph" type="paragraph">
                    El número de serie (NIV o VIN) está localizado en 4 zonas de tu auto y en el carnet de circulación del auto. (Es un número de 17 caracteres alfanuméricos)
                  </Text>

                  <Text type="paragraph">1. Bajo el cofre, en la cara frontal del motor.</Text>
                  <Text type="paragraph">2. En la esquina inferior izquierda del parabrisas.</Text>
                  <Text type="paragraph">3. En el área del chasis, sobre la rueda delantera.</Text>
                  <Text type="paragraph">4. En el marco de la puerta del conductor.</Text>
                </div>

                <Button onClick={() => setShowVehicleVin(false)}>Entendido</Button>
              </div>
            </Modal>
          </>
          :
          <div className="plan__loader">
            <Spinner color="main" relative />
          </div>
      }
    </div >
  );
};

export default QuoterPlanPage;